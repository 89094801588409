const igEpicsMap = {
    "AGT": "KA.D.BTEM.CASH.IP",
    "ASL": "KA.D.ASL.CASH.IP",
    "ALW": "KA.D.ATST.CASH.IP",
    "ATT": "KA.D.RTT.CASH.IP",
    "BBH": "KC.D.BBHLN.CASH.IP",
    "BGFD": "KA.D.BGFD.CASH.IP",
    "BGS": "KA.D.BGS.CASH.IP",
    "BIOG": "KA.D.BIOG.CASH.IP",
    "BNKR": "KA.D.BNKR.CASH.IP",
    "BRSC": "KA.D.BRSC.CASH.IP",
    "CTY": "KA.D.CTY.CASH.IP",
    "EDIN": "KA.D.EDIN.CASH.IP",
    "EOT": "KA.D.JEO.CASH.IP",
    "EWI": "KA.D.EWI.CASH.IP",
    "FCIT": "KA.D.FRCL.CASH.IP",
    "FCSS": "KA.D.FCSS.CASH.IP",
    "FEV": "KA.D.FEV.CASH.IP",
    "FGT": "KA.D.FGT.CASH.IP",
    "FSV": "KA.D.FSV.CASH.IP",
    "HRI": "KA.D.HRI.CASH.IP",
    "HSL": "KA.D.HSL.CASH.IP",
    "JAGI": "KA.D.JAILN.CASH.IP",
    "JAM": "KA.D.JAM.CASH.IP",
    "JCGI": "KA.D.JMC.CASH.IP",
    "JCH": "KA.D.JCH.CASH.IP",
    "JEDT": "KA.D.JFF.CASH.IP",
    "JEMI": "KA.D.JEMILN.CASH.IP",
    "JFJ": "KA.D.JFJ.CASH.IP",
    "JGGI": "KA.D.JMO.CASH.IP",
    "JII": "KA.D.JII.CASH.IP",
    "JMF": "KA.D.JMF.CASH.IP",
    "JMG": "KA.D.JMG.CASH.IP",
    "JUGI": "KA.D.JMI.CASH.IP",
    "JSGI": "KA.D.JPS.CASH.IP",
    "JUSC": "KA.D.JPU.CASH.IP",
    "LWDB": "KA.D.LWDB.CASH.IP",
    "MNKS": "KA.D.MNKS.CASH.IP",
    "MRC": "KA.D.JFM.CASH.IP",
    "MUT": "KA.D.MUT.CASH.IP",
    "MYI": "KA.D.MYI.CASH.IP",
    "PCT": "KA.D.PCT.CASH.IP",
    "PHI": "KA.D.PHI.CASH.IP",
    "PNL": "KA.D.PNLLN.CASH.IP",
    "SAIN": "KA.D.SCAM.CASH.IP",
    "SMT": "KA.D.SMT.CASH.IP",
    "SSON": "KA.D.SSONLN.CASH.IP",
    "TEM": "KA.D.TEM.CASH.IP",
    "THRG": "KA.D.THRG.CASH.IP",
    "TMPL": "KA.D.TMPL.CASH.IP",
    "USA": "KA.D.USALN.CASH.IP",
    "WTAN": "KA.D.WTAN.CASH.IP",
    "WWH": "KA.D.FWP.CASH.IP",
    "HFEL": "KA.D.HFELLN.CASH.IP",
    "HET": "KA.D.GEO.CASH.IP",
    "ESCT": "KA.D.TRG.CASH.IP",
    "HNE": "KA.D.HNE.CASH.IP",
    "HHI": "KA.D.HHI.CASH.IP",
    "HOT": "KA.D.HOTLN.CASH.IP",
    "LWI": "KA.D.LWI.CASH.IP",
    "HINT": "KA.D.HINTLN.CASH.IP",
    "JEGI": "KA.D.JEGILN.CASH.IP",
    "AEI": "KA.D.SLET.CASH.IP",
    "AUSC": "KA.D.SLS.CASH.IP",
    "NAIT": "KA.D.EUS.CASH.IP",
    "DGN": "KA.D.EFM.CASH.IP",
    "AAIF": "KA.D.AAIF.CASH.IP",
    "AAS": "KA.D.AAS.CASH.IP",
    "ANII": "KA.D.NII.CASH.IP",
    "DIG": "KA.D.DIG.CASH.IP",
    "BGCG": "KA.D.WPC.CASH.IP",
    "BGEU": "KA.D.FCU.CASH.IP",
    "BGUK": "KA.D.SDU.CASH.IP",
    "KPC": "KA.D.KIT.CASH.IP",
    "BUT": "KA.D.BUT.CASH.IP",
    "MRCH": "KA.D.MRCH.CASH.IP",
    "SJG": "KA.D.SJG.CASH.IP",
    "SCP": "KA.D.SCP.CASH.IP",
    "SDP": "KA.D.SDP.CASH.IP",
    "ATR": "KA.D.HPI.CASH.IP",
    "SCF": "KA.D.SCF.CASH.IP",
    "SOI": "KA.D.SOILN.CASH.IP",
    "IBT": "KA.D.IBT.CASH.IP",
    "PCGH": "KA.D.PCGHLN.CASH.IP",
    "BRAI": "KA.D.BRAILN.CASH.IP",
    "BRWM": "KA.D.BRWM.CASH.IP",
    "BERI": "KA.D.BRCI.CASH.IP",
    "MTE": "KA.D.MTELN.CASH.IP",
    "BRGE": "KA.D.BRGE.CASH.IP"
}

const igEpicMap = {
    'FWP': 'WWH',
    'RTT': 'ATT',
    'JFM': 'MRC',
    'SCAM': 'SAIN',
    'JPS': 'JSGI',
    'JEO': 'EOT',
    'BTEM': 'AGT',
    'FRCL': 'FCIT',
    'JMO': 'JGGI',
    'JFF': 'JEDT',
    'JMC': 'JCGI',
    'JAILN': 'JAGI',
    'JPU': 'JUSC',
    'TRG': 'ESCT',
    'GEO': 'HET',
    'JMI': 'JUGI',
    'SLET': 'AEI',
    'SLS': 'AUSC',
    'EUS': 'NAIT',
    'EFM': 'DGN',
    'NII': 'ANII',
    'WPC': 'BGCG',
    'FCU': 'BGEU',
    'SDU': 'BGUK',
    'KIT': 'KPC',
    'HPI': 'ATR',
    'ATST': 'ALW',
    'BRCI': 'BERI'
}

const asNumber = (input, negative) => {
    if(["", "0"].includes(input)) return "0"
    var output = [",","."].includes(input) ? "0.0":input.replace(/[,\\.]/, "|").replace(/[,\\.]/g, '').replace("|", ".")
    if(negative){
        output = output.match(output.includes(".") ? /\-?([0-9]*)*\.[0-9]{0,3}/:/\-([0-9]+)*/)[0]
    } else {
        output = output.match(output.includes(".") ? /([0-9]*)\.[0-9]{0,3}/:/([1-9][0-9]*)/)[0]
    }
    return output
}

const mean = (arr) => {
    const sum = arr.reduce((acc, val) => acc + val, 0);
    return sum / arr.length;
}

const std = (arr) => {
    const avg = mean(arr);
    const squaredDiffs = arr.map(num => Math.pow(num - avg, 2));
    const avgSquaredDiff = squaredDiffs.reduce((acc, val) => acc + val, 0) / arr.length;
    return Math.sqrt(avgSquaredDiff);
}

const cap = str => str.toUpperCase()[0]+str.slice(1)

// Function that recursively find the value of an object path. It only works for objects and path arrays
const deepPath = (obj, path) => path.length > 1 ? deepPath(obj?.[path[0]], path.slice(1)):obj?.[path[0]]

const deepUpdate = (obj, path, newValue) => {return {...obj, [path[0]]: path.length > 1 ? deepUpdate(obj?.[path[0]], path.slice(1), newValue):newValue}}

const shortTimeFormat = dateObject => `${dateObject.getDate()}/${dateObject.getMonth()+1}-${dateObject.toLocaleTimeString()}`

const shortDateFormat = dateObject => `${dateObject.getDate()}/${dateObject.getMonth()+1}-${dateObject.getFullYear()}`

// For using apply inside setTimeout calls when wanting to remove a loading cursor
const removeLoadingClass = node => {
    node.classList.remove("wait")
}

class BoundedArray {
    constructor(maxLength) {
        this.maxLength = maxLength;
        this.array = [];
    }

    push(value) {
        // Add the new value to the array
        this.array.push(value);
        
        // If the array exceeds the max length, remove the oldest item
        if (this.array.length > this.maxLength) {
            this.array.shift();
        }
    }

    getArray() {
        return this.array;
    }
}

const calculateNetPositions = (positions) => {
    const netPositions = {}

    positions.forEach(position => {
        const epic = position?.market?.epic ? position?.market?.epic : position?.position?.epic
        let ticker;
        if (Object.values(igEpicsMap)?.includes(epic)) {
            ticker = epic?.split('.')?.[2] in igEpicMap ? igEpicMap[epic?.split('.')?.[2]] : epic?.split('.')?.[2]?.split('LN')?.[0]
        } else {
            ticker = epic?.split('.')?.[2]+'.'+epic?.split('.')?.[3];
        } 
        if (!(ticker in netPositions)) {
            netPositions[ticker] = {
                epic: epic,
                instrumentName: position?.market?.instrumentName,
                netSize: 0,
                absSize: 0,
                openLevel: 0,
                curPrice: (position?.market?.high || position?.position?.level),
                contractSize: position?.position?.contractSize,
                currency: position?.position?.currency,
                instrumentType: position?.market?.instrumentType,
                createdDate: position?.position?.createdDate ? position?.position?.createdDate : position?.position?.timestamp,
            };
        }
        if (position?.position?.direction === "BUY") {
            netPositions[ticker]["netSize"] += position?.position?.size
        } else if (position?.position?.direction === "SELL") {
            netPositions[ticker]["netSize"] -= position?.position?.size
        }

        netPositions[ticker]["absSize"] += position?.position?.size
        netPositions[ticker]["openLevel"] += position?.position?.level*position?.position?.size
    });

    for (let key in netPositions) {
        netPositions[key]["openLevel"] = netPositions[key]?.openLevel / netPositions[key]?.absSize
        netPositions[key]["exposure"] = netPositions[key]?.curPrice*netPositions[key]?.netSize
    }

    return Object.values(netPositions);
};

const calculateSectorsHedgingError = (hedgingErrors, igNetPositions, fundSectors, fxLastPrices) => {
    let res = {
        'sectors': {},
        'funds': {}
    }
    for (let sector in fundSectors) {
        res['sectors'][sector] = {};
        let sectorExposure = 0;
        let fundExposures = {};
        for (let i=0; i<igNetPositions?.length; i++) {
            const ticker = Object.keys(igEpicsMap)?.find(key => igEpicsMap[key] === igNetPositions?.[i]?.epic);
            if (fundSectors[sector]?.includes(ticker)) {
                sectorExposure += Math.abs(igNetPositions?.[i]?.exposure/100);
                fundExposures[ticker] = igNetPositions?.[i]?.exposure/100;
                res['funds'][ticker] = {
                    'hedgingErrorGBP': ((igNetPositions?.[i]?.exposure/100)*hedgingErrors?.[ticker])*fxLastPrices?.["GBPDKK"],
                    'exposure': igNetPositions?.[i]?.exposure/100
                };
            }
        }

        // Porfolio hedging error weighted.
        let sectorHedgingErrorPortfolioPercentage = 0;
        let sectorHedgingErrorPortfolio = 0;
        for (let ticker in fundExposures) {
            sectorHedgingErrorPortfolioPercentage += (Math.abs(fundExposures[ticker])/sectorExposure)*hedgingErrors?.[ticker];
            sectorHedgingErrorPortfolio += fundExposures[ticker]*hedgingErrors?.[ticker];
        }
        res['sectors'][sector]['portfolioPercentage'] = sectorHedgingErrorPortfolioPercentage;
        res['sectors'][sector]['portfolio'] = sectorHedgingErrorPortfolio*fxLastPrices?.["GBPDKK"];

        // Hedging error for all tickers in sector.
        let sectorHedgingErrorAll = 0;
        for (let i=0; i<fundSectors[sector]?.length; i++) {
            sectorHedgingErrorAll += hedgingErrors?.[fundSectors[sector]?.[i]] || 0;
        }
        res['sectors'][sector]['all'] = sectorHedgingErrorAll / fundSectors[sector]?.length;
    }

    return res;
};

const standard_notification = {
    insert: "bottom",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["fadeOut"],
    dismiss: {
      duration: 5000,
      pauseOnHover: true,
      showIcon: true
    }
  }

const default_settings = {
    view: {account_overview: true, active_orders: true, trade_overview: true, dev_tools: false, 
    observation_lists: {
        main: true,
        hedging: false
    }},
    columns:{
        trading:{symbol: true, name: false, last_price: true, bid_size: true, bid_price: true, ask_price: true, ask_size: true,price_sync: true, custom_algorithms: false, time_in_force: false},
        active_orders: {price: true, quantity: true, updated: true, time_in_force: true, custom_algorithms:true, iceberg_shown: true}
    },
    orders: {
        hide_agg: false
    },
    text_size: 10
}

const tricap_token_key = "_tricap_token_"

const switch_default = {uncheckedIcon:false, checkedIcon: false, height: 20, width: 42}

export {asNumber, cap, deepPath,deepUpdate, shortTimeFormat, removeLoadingClass, shortDateFormat, mean, std, calculateNetPositions, calculateSectorsHedgingError,
    standard_notification, default_settings, tricap_token_key, switch_default, BoundedArray};